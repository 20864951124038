import { Injectable } from '@angular/core';
import { PDFDocument } from '../interfaces/pdf-document.interface';

@Injectable({
  providedIn: 'root'
})
export class PdfTableService {
  private static readonly pageHeight: number = 792; // US Letter height in points
  private static readonly marginBottom: number = 50;
  private static readonly pageWidth: number = 612; // US Letter width in points
  private static readonly marginLeft: number = 50;
  private static readonly marginRight: number = 50;
  private static readonly minRowHeight: number = 25;
  private static readonly cellPadding: number = 5;

  private static calculateCellHeight(doc: PDFDocument, text: string, columnWidth: number): number {
    const textHeight = doc.heightOfString(String(text), {
      width: columnWidth - (this.cellPadding * 2)
    });
    return Math.max(this.minRowHeight, textHeight + (this.cellPadding * 2));
  }

  private static getRowHeight(doc: PDFDocument, row: any[], columnWidth: number): number {
    return Math.max(...row.map(cell => 
      this.calculateCellHeight(doc, String(cell), columnWidth)
    ));
  }

  static drawTable(doc: PDFDocument, headers: string[], rows: any[][], startY?: number): number {
    let currentY = startY || doc.y;
    const columnCount = headers.length;
    const columnWidth = (this.pageWidth - this.marginLeft - this.marginRight) / columnCount;

    // Draw headers
    doc.font('Helvetica-Bold').fontSize(10);
    const headerHeight = this.getRowHeight(doc, headers, columnWidth);
    
    // Check if headers need a new page
    if (currentY + headerHeight > this.pageHeight - this.marginBottom) {
      doc.addPage();
      currentY = 100;
    }

    headers.forEach((header, i) => {
      // Draw cell border
      doc.lineWidth(0.5)
         .rect(this.marginLeft + (i * columnWidth), currentY, columnWidth, headerHeight)
         .strokeColor('gray')
         .stroke();
      
      // Draw header text
      doc.text(header, 
               this.marginLeft + (i * columnWidth) + this.cellPadding, 
               currentY + this.cellPadding,
               { width: columnWidth - (this.cellPadding * 2), align: 'left' });
    });
    currentY += headerHeight;

    // Draw rows
    doc.font('Helvetica').fontSize(10);
    rows.forEach(row => {
      const rowHeight = this.getRowHeight(doc, row, columnWidth);
      
      // Check if we need a new page
      if (currentY + rowHeight > this.pageHeight - this.marginBottom) {
        doc.addPage();
        currentY = 100;
      }

      row.forEach((cell, i) => {
        // Draw cell border
        doc.lineWidth(0.5)
           .rect(this.marginLeft + (i * columnWidth), currentY, columnWidth, rowHeight)
           .strokeColor('gray')
           .stroke();
        
        // Draw cell text
        doc.text(String(cell), 
                 this.marginLeft + (i * columnWidth) + this.cellPadding, 
                 currentY + this.cellPadding,
                 { width: columnWidth - (this.cellPadding * 2), align: 'left' });
      });
      currentY += rowHeight;
    });

    return currentY;
  }
} 