import { Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MarkdownComponent, MarkdownService } from 'ngx-markdown';
import { PDFDocument } from 'src/app/core/interfaces/pdf-document.interface';

@Component({
  selector: 'app-markdown-renderer',
  standalone: true,
  providers: [MarkdownService],
  imports: [
    CommonModule,
    MarkdownComponent
  ],
  template: `
    <markdown [data]="convertText()"></markdown>
  `
})
export class MarkdownRendererComponent {
  @Input() text: string = '';

  convertText(): string {
    return MarkdownRendererComponent.convertToMarkdown(this.text);
  }

  static convertToMarkdown(text: string): string {
    if (!text) return '';
    
    // Split into lines and process each line
    return text.split('\n').map(line => {
      // Convert bold text to headers
      if (line.startsWith('**') && line.endsWith('**')) {
        return '### ' + line.replace(/\*\*/g, '');
      }
      return line;
    }).join('\n\n');
  }

  static renderToPdf(doc: PDFDocument, markdown: string, marginLeft: number, width: number): void {

    if (!markdown) return;

    const convertedMarkdown = MarkdownRendererComponent.convertToMarkdown(markdown);
    const lines = convertedMarkdown.split('\n\n');

    for (const line of lines) {
      if (line.startsWith('### ')) {
        // Header 3 (previously bold text)
        doc.font('Helvetica-Bold')
           .fontSize(12)
           .text(line.replace('### ', ''), marginLeft, doc.y, {
             width,
             continued: false
           });
        doc.moveDown(0.5);
      } else if (line.startsWith('## ')) {
        // Header 2
        doc.font('Helvetica-Bold')
           .fontSize(14)
           .text(line.replace('## ', ''), marginLeft, doc.y, {
             width,
             continued: false
           });
        doc.moveDown(0.5);
      } else if (line.startsWith('# ')) {
        // Header 1
        doc.font('Helvetica-Bold')
           .fontSize(16)
           .text(line.replace('# ', ''), marginLeft, doc.y, {
             width,
             continued: false
           });
        doc.moveDown(0.5);
      } else if (line.match(/^[-*+]\s/)) {
        // Bullet points
        doc.font('Helvetica')
           .fontSize(10)
           .text(line.replace(/^[-*+]\s/, '• '), marginLeft + 10, doc.y, {
             width: width - 10,
             continued: false
           });
        doc.moveDown(0.5);
      } else {
        // Regular text
        doc.font('Helvetica')
           .fontSize(10)
           .text(line, marginLeft, doc.y, {
             width,
             align: 'left',
             continued: false
           });
        doc.moveDown(0.5);
      }
    }
  }
} 