import { Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReportFieldDirective } from 'src/app/core/directives/report-field.directive';
import { MarkdownComponent, MarkdownService } from 'ngx-markdown';
import { SanctionsComponent } from '../sanctions/sanctions.component';
import { PDFDocument } from 'src/app/core/interfaces/pdf-document.interface';
import { PdfTableService } from 'src/app/core/services/pdf-table.service';

@Component({
  selector: 'sanctions-media-intelligence',
  standalone: true,
  providers: [MarkdownService],
  imports: [
    CommonModule,
    MarkdownComponent,
    SanctionsComponent,
    ReportFieldDirective
  ],
  template: `
    <div class="flex w-full flex-wrap my-5">
      <div class="w-full border-1 border-grey px-2" *reportField="'sanctions.media'; let mediaIntelligence">
        <app-sanctions *ngIf="mediaIntelligence" [data]="mediaIntelligence" [field]="'summary'"></app-sanctions>
      </div>
    </div>
  `
})
export class SanctionsMediaIntelligenceComponent {
  @Input() printMode: boolean = false;

  constructor(private pdfTableService: PdfTableService) {}

  static renderToPdf(doc: PDFDocument, data: any): void {
    doc.addPage();

    let currentY = doc.y;

    // Add title section
    doc.rect(50, currentY, 512, 30) // 512 = 612 - 50 - 50 (page width - margins)
       .fill('#172554'); // bg-blue-950
    
    doc.fontSize(14)
       .font('Helvetica-Bold')
       .fill('#ffffff')
       .text('Sanctions Media Intelligence', 50, currentY + 8, { 
         align: 'center',
         width: 512
       });
    
    currentY += 40;

    // If there's media intelligence data, use the sanctions component to render it
    if (data.sanctions?.media) {
       SanctionsComponent.renderToPdf(doc, data.sanctions.media);
    }

  }
}