import { Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReportFieldDirective } from 'src/app/core/directives/report-field.directive';
import { PDFDocument } from 'src/app/core/interfaces/pdf-document.interface';
import { MarkdownRendererComponent } from '../../markdown-renderer/markdown-renderer.component';

@Component({
  selector: 'app-sanctions',
  standalone: true,
  imports: [
    CommonModule,
    MarkdownRendererComponent,
    ReportFieldDirective
  ],
  template: `
    <div class="flex w-full flex-wrap my-5">
      <div class="w-full border-1 border-grey px-2">
        <div  *ngIf="data.companies.length > 0">
        <div class="text-xl font-bold mb-2 bg-bgColor p-1">Companies</div>
        <div *ngFor="let company of data.companies">
            <h4>{{ company.name }}</h4>
            <app-markdown-renderer [text]="company[field]"></app-markdown-renderer>
        </div>
        </div>
        <div *ngIf="data.individuals.length > 0">
        <div class="text-xl font-bold mb-2 bg-bgColor p-1">Individuals</div>
        <div *ngFor="let individual of data.individuals">
            <h4>{{ individual.name }}</h4>
            <app-markdown-renderer [text]="individual[field]"></app-markdown-renderer>
        </div>
        </div>
      </div>
    </div>
  `
})
export class SanctionsComponent {
  @Input() printMode: boolean = false;
  @Input() data: any;
  @Input() field: string = 'summary';

  static renderToPdf(doc: PDFDocument, data: any, field: string = 'summary'): void {
    // Ensure currentY is a valid number
    let currentY = doc.y;

    const marginLeft = 50;
    const marginRight = 50;
    const pageWidth = 612; // US Letter width
    const contentWidth = pageWidth - marginLeft - marginRight;

    // Companies Section
    if (data.companies && data.companies.length > 0) {
      // Companies Header
      doc.rect(marginLeft, currentY, contentWidth, 30)
         .fill('#f3f4f6'); // bg-bgColor
      
      doc.fontSize(14)
         .font('Helvetica-Bold')
         .fill('#000000')
         .text('Companies', marginLeft + 10, currentY + 8);
      
      currentY += 30;
      doc.moveDown(2);
      currentY = doc.y;

      // Companies Content
      for (const company of data.companies) {
        doc.fontSize(12)
           .font('Helvetica-Bold')
           .text(company.name, marginLeft, currentY, {
             continued: false
           });

        currentY = doc.y;
        doc.moveDown();
        currentY = doc.y;

        // Company Details - Use markdown renderer
        MarkdownRendererComponent.renderToPdf(doc, company[field], marginLeft, contentWidth);
        currentY = doc.y;
        doc.moveDown(2);
        currentY = doc.y;
      }
    }

    // Individuals Section
    if (data.individuals && data.individuals.length > 0) {
      // Individuals Header
      doc.rect(marginLeft, currentY, contentWidth, 30)
         .fill('#f3f4f6'); // bg-bgColor
      
      doc.fontSize(14)
         .font('Helvetica-Bold')
         .fill('#000000')
         .text('Individuals', marginLeft + 10, currentY + 8);
      
      currentY += 30;
      doc.moveDown(2);
      currentY = doc.y;

      // Individuals Content
      for (const individual of data.individuals) {
        doc.fontSize(12)
           .font('Helvetica-Bold')
           .text(individual.name, marginLeft, currentY, {
             continued: false
           });

        currentY = doc.y;
        doc.moveDown();
        currentY = doc.y;

        // Individual Details - Use markdown renderer
        MarkdownRendererComponent.renderToPdf(doc, individual[field], marginLeft, contentWidth);
        currentY = doc.y;
        doc.moveDown(2);
        currentY = doc.y;
      }
    }

 }
}