import { Injectable, Injector } from '@angular/core';
import { ReportSection } from 'src/app/shared/components/diligence/report-sections';
import { PDFDocument } from '../interfaces/pdf-document.interface';
import { PdfTableService } from './pdf-table.service';

interface BlobStream {
  toBlobURL: (type: string) => string;
  on: (event: string, callback: (error?: Error) => void) => void;
}

declare global {
  interface Window {
    PDFKit: {
      PDFDocument: new (options?: { bufferPages?: boolean; margins?: { top: number; bottom: number; left: number; right: number } }) => PDFDocument;
      blobStream: () => BlobStream;
    };
  }
}

@Injectable({
  providedIn: 'root'
})
export class PdfService {
  private readonly DISCLAIMER_ANCHOR = 'disclaimer_section';

  private loadImage(src: string): Promise<string> {
    return new Promise((resolve, reject) => {
      const img = new Image();
      img.crossOrigin = 'Anonymous';
      img.onload = () => {
        const canvas = document.createElement('canvas');
        canvas.width = img.width;
        canvas.height = img.height;
        const ctx = canvas.getContext('2d');
        ctx?.drawImage(img, 0, 0);
        resolve(canvas.toDataURL('image/png'));
      };
      img.onerror = reject;
      img.src = src;
    });
  }

  async generateReportPdf(sections: ReportSection[], packageName: string, data: any, dueDiligence: any): Promise<void> {
    return new Promise(async (resolve, reject) => {
      try {
        const logoDataUrl = await this.loadImage('../../../assets/TevunaLogo.svg');
        const doc = new window.PDFKit.PDFDocument({
          bufferPages: true,
          margins: { top: 50, bottom: 50, left: 50, right: 50 }
        });

        const stream = doc.pipe(window.PDFKit.blobStream());
        const companyName = data.generalInfo?.companyName;

        this.addTitlePage(doc, packageName, companyName, logoDataUrl);

        sections.forEach(section => {
          if (section.print) {
            this.addSection(doc, section, data, dueDiligence);
          }
        });

        // Add page numbers to all pages
        const pages = doc.bufferedPageRange();
        for (let i = 0; i < pages.count; i++) {
          doc.switchToPage(i);

          // Save the old margin
          const oldBottomMargin = doc.page.margins.bottom;
          // Temporarily remove bottom margin
          doc.page.margins.bottom = 0;

          doc.fontSize(10)
             .text(
               `Page ${i + 1} of ${pages.count}`,
               doc.page.width - 150,
               doc.page.height - (oldBottomMargin / 2),
               { align: 'right' }
             );

          // Restore the margin
          doc.page.margins.bottom = oldBottomMargin;
        }
        
        doc.end();

        stream.on('finish', () => {
          try {
            const url = stream.toBlobURL('application/pdf');
            const link = document.createElement('a');
            link.href = url;
            link.download = `${companyName.toLowerCase().replace(/\s+/g, '-')}.pdf`;
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
            resolve();
          } catch (error) {
            reject(error instanceof Error ? error : new Error(String(error)));
          }
        });

        stream.on('error', (error: Error) => {
          reject(error);
        });
      } catch (error) {
        reject(error instanceof Error ? error : new Error(String(error)));
      }
    });
  }

  private addTitlePage(doc: PDFDocument, packageName: string, companyName: string, logoDataUrl: string): void {
    // Add logo - centered horizontally (page width is 612, so center is 306)
    doc.image(logoDataUrl, 156, 50, {
      fit: [300, 150],
      align: 'center',
      valign: 'center'
    });
    
    // Add text with Times-Roman font which has better Cyrillic support
    doc.fontSize(25)
       .font('Helvetica-Bold')
       .fillColor('#172554')
       .text(companyName, 100, 250, { align: 'center' })
       .moveDown(1)
       .fontSize(25)
       .font('Helvetica')
       .text(`${packageName} Report`, 100, 300, { align: 'center' })
       .moveDown(1)
       .fontSize(10)
       .fillColor('blue')
       .text('View report in browser', 100, doc.y, { 
         align: 'center',
         link: window.location.href,
         underline: true 
       })
       .fillColor('#172554')
       .moveDown(8)
       .moveDown(2)
       .fontSize(12)
       .text(`Generated on ${new Date().toLocaleDateString()}`, 100, 450, { align: 'center' })
       .fontSize(10)
       .text('By proceeding, you acknowledge and agree to be bound by the Disclaimer', 100, 500, { 
         align: 'center',
         goTo: this.DISCLAIMER_ANCHOR,
         underline: true 
       });
    
    doc.addPage();
    doc.fillColor('black'); // Reset color for next pages
  }

  private addSection(doc: PDFDocument, section: ReportSection, data: any, dueDiligence: any): void {
    const ComponentClass = section.component;
    if (ComponentClass.renderToPdf) {
      ComponentClass.renderToPdf(doc, data, dueDiligence);
    }
    doc.moveDown(10);
  }
} 