// report-sections.ts
import { Type } from '@angular/core';
import { SummaryComponent } from 'src/app/shared/components/diligence/summary/summary.component';
import { BusinnesAffiliationsComponent } from 'src/app/shared/components/diligence/business-affiliations/businnes-affiliations.component';
import { LegalSummaryComponent } from 'src/app/shared/components/diligence/legal-summary/legal-summary.component';
import {PaymentDataComponent} from 'src/app/shared/components/diligence/payment-data/payment-data.component';
import {DirectorsComponent} from 'src/app/shared/components/diligence/directors/directors.component';
import {MediaIntelligenceComponent} from 'src/app/shared/components/diligence/media-intelligence/media-intelligence.component';
import { SourcesComponent } from './sources/sources.component';
import { SanctionsScreeningComponent } from './sanction-screening/sanction-screening.component';
import { SanctionsBillOfLadingComponent } from './bill-of-lading/bill-of-lading.component';
import { SanctionsMediaIntelligenceComponent } from './sanctions-media-intelligence/sanctions-media-intelligence.component';
import { SanctionsUsaRelationshipComponent } from './sanctions-usa-relationship/sanctions-usa-relationship.component';
import { SanctionsSummaryComponent } from './sanctions-summary/sanction-summary.component';
import { DisclaimerComponent } from 'src/app/shared/components/diligence/disclaimer/disclaimer.component';

export interface ReportSection {
  id: string;
  title: string;
  component: any;
  display: boolean;
  print: boolean;
  data?: Record<string, any>;
}

export function buildDiligeceReportSections(data: any, pdfView: boolean): ReportSection[] {
  const sections: ReportSection[] =

  [
    {
    id: 'summary',
    title: 'Summary',
    component: SummaryComponent,
    display: true,
    print: true,
    data: {printMode: pdfView}
  },
  {
    id: 'businessAffiliations',
    title: 'Business Affiliations',
    component: BusinnesAffiliationsComponent,
    display: data.businessAffiliations.current.length || data.businessAffiliations.previous.length,
    print: data.businessAffiliations.current.length || data.businessAffiliations.previous.length,
    data: {printMode: pdfView}
  },
  {
    id: 'legalFilings',
    title: 'Legal Filings',
    component: LegalSummaryComponent,
    display: data.legalFilings.activeJudgements.length || data.legalFilings.releasedJudgements.length || data.legalFilings.activeSuits.length || data.legalFilings.releasedSuits.length,
    print: data.legalFilings.activeJudgements.length || data.legalFilings.releasedJudgements.length || data.legalFilings.activeSuits.length || data.legalFilings.releasedSuits.length,
    data: {releasedJudgements: data.legalFilings.releasedJudgements, activeJudgements: data.legalFilings.activeJudgements, releasedSuits: data.legalFilings.releasedSuits, activeSuits: data.legalFilings.activeSuits, printMode: pdfView}
  },
  {
    id: 'keyFinancials',
    title: 'Key Financials',
    component: PaymentDataComponent,
    display: data.paymentData,
    print: data.paymentData,
    data: {outstandingExperiencesInTheLast6Months: data.paymentData, printMode: pdfView}
  },
  {
    id: 'directors',
    title: 'People and Ownership',
    component: DirectorsComponent,
    display: data.directors || data.shareholders || (data.people && data.people.length),
    print: data.directors || data.shareholders || (data.people && data.people.length),
    data: {directors: data.directors, shareholders: data.shareholders, printMode: pdfView}
  },
  {
    id: 'mediaIntelligence',
    title: 'Media Intelligence',
    component: MediaIntelligenceComponent,
    display: true,
    print: true,
    data: {critIssueDetails: data.tevunaReport.critIssueDetails, printMode: pdfView}
  },
  {
    id: 'sources',
    title: 'Sources',
    component: SourcesComponent,
    display: true,
    print: true,
    data: {printMode: pdfView}
  },
  {
    id: 'disclaimer',
    title: 'Disclaimer',
    component: DisclaimerComponent,
    display: false,
    print: true,
    data: {printMode: pdfView}
  }
]

  return sections;
}

export function buildSanctionsReportSections(data: any, pdfView: boolean): ReportSection[] {
  const sections: ReportSection[] =

  [
  {
    id: 'sanctionsSummary',
    title: 'Summary',
    component: SanctionsSummaryComponent,
    display: true,
    print: true,
    data: {printMode: pdfView}
  },   
  {
    id: 'businessAffiliations',
    title: 'Business Affiliations',
    component: BusinnesAffiliationsComponent,
    display: data.businessAffiliations.current.length || data.businessAffiliations.previous.length,
    print: data.businessAffiliations.current.length || data.businessAffiliations.previous.length,
    data: {printMode: pdfView}
  },
  {
    id: 'legalFilings',
    title: 'Legal Filings',
    component: LegalSummaryComponent,
    display: data.legalFilings.activeJudgements.length || data.legalFilings.releasedJudgements.length || data.legalFilings.activeSuits.length || data.legalFilings.releasedSuits.length,
    print: data.legalFilings.activeJudgements.length || data.legalFilings.releasedJudgements.length || data.legalFilings.activeSuits.length || data.legalFilings.releasedSuits.length,
    data: {releasedJudgements: data.legalFilings.releasedJudgements, activeJudgements: data.legalFilings.activeJudgements, releasedSuits: data.legalFilings.releasedSuits, activeSuits: data.legalFilings.activeSuits, printMode: pdfView}
  },
  {
    id: 'keyFinancials',
    title: 'Key Financials',
    component: PaymentDataComponent,
    display: data.paymentData,
    print: data.paymentData,
    data: {outstandingExperiencesInTheLast6Months: data.paymentData, printMode: pdfView}
  },
  {
    id: 'sanctionScreening',
    title: 'Sanction Screening',
    component: SanctionsScreeningComponent,
    display: data.sanctions.screening.companies.length || data.sanctions.screening.individuals.length,
    print: data.sanctions.screening.companies.length || data.sanctions.screening.individuals.length,
    data: {printMode: pdfView}
  },
  {
    id: 'traceData',
    title: 'Trade Data',
    component: SanctionsBillOfLadingComponent,
    display: data.sanctions.billOfLading.companies.length || data.sanctions.billOfLading.individuals.length,
    print: data.sanctions.billOfLading.companies.length || data.sanctions.billOfLading.individuals.length,
    data: {printMode: pdfView, billOfLading: data.sanctions.billOfLading}
  },
  {
    id: 'usaRelationship',
    title: 'USA Relationship',
    component: SanctionsUsaRelationshipComponent,
    display: data.sanctions.usaRelationship.companies.length || data.sanctions.usaRelationship.individuals.length,
    print: data.sanctions.usaRelationship.companies.length || data.sanctions.usaRelationship.individuals.length,
    data: {printMode: pdfView}
  },
  {
    id: 'MediaIntelligence',
    title: 'Media Intelligence',
    component: SanctionsMediaIntelligenceComponent,
    display: true,
    print: true,
    data: {printMode: pdfView}
  }, 
  {
    id: 'people',
    title: 'People and Ownership',
    component: DirectorsComponent,
    display: data.directors || data.shareholders || (data.people && data.people.length),
    print: data.directors || data.shareholders || (data.people && data.people.length),
    data: {directors: data.directors, shareholders: data.shareholders, printMode: pdfView}
  },
  {
    id: 'sources',
    title: 'Sources',
    component: SourcesComponent,
    display: true,
    print: true,
    data: {printMode: pdfView}
  },
  {
    id: 'disclaimer',
    title: 'Disclaimer',
    component: DisclaimerComponent,
    display: false,
    print: true,
    data: {printMode: pdfView}
  } 
]

  return sections;
}

export function updateSectionProps(sections: ReportSection[], sectionId: string, data: Record<string, any>): ReportSection[] {
  return sections.map(section => 
    section.id === sectionId ? { ...section, data: { ...section.data, ...data } } : section
  );
}

export function updateSectionVisibility(sections: ReportSection[], sectionId: string, display: boolean, print: boolean): ReportSection[] {
  return sections.map(section => 
    section.id === sectionId ? { ...section, display, print } : section
  );
}