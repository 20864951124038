import { Component, OnInit, inject } from '@angular/core';
import { NavbarComponent } from "../../shared/components/navbar/navbar.component";
import { AuthService } from '@auth0/auth0-angular';
import { DiligenceQueryParams } from 'src/app/core/services/api.service';
import { CommonModule } from '@angular/common';
import { MatTabsModule } from '@angular/material/tabs';
import { ApiService } from 'src/app/core/services/api.service';
import { switchMap } from 'rxjs/operators';
import { MatTableModule } from '@angular/material/table';
import { MatIconModule } from '@angular/material/icon';
import { FooterComponent } from "../../shared/components/footer/footer.component";
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatPaginatorModule, PageEvent } from '@angular/material/paginator';
import { MatSortModule, Sort } from '@angular/material/sort';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { MatChipsModule } from '@angular/material/chips';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { Subject, Observable, forkJoin } from 'rxjs';
import { debounceTime, distinctUntilChanged, map, startWith } from 'rxjs/operators';
import { FormControl } from '@angular/forms';
import { packages } from 'src/app/core/models/packages';

interface TabInfo {
  label: string;
  icon: string;
  phases: number[];
  color: string;
}

@Component({
    selector: 'app-admin',
    templateUrl: './admin.component.html',
    styleUrls: ['./admin.component.scss'],
    standalone: true,
    imports: [
        NavbarComponent,
        CommonModule,
        MatTabsModule,
        MatTableModule,
        MatIconModule,
        FooterComponent,
        MatProgressSpinnerModule,
        MatPaginatorModule,
        MatSortModule,
        MatFormFieldModule,
        MatInputModule,
        MatSelectModule,
        MatChipsModule,
        MatAutocompleteModule,
        FormsModule,
        ReactiveFormsModule
    ]
})
export class AdminComponent implements OnInit {
    auth = inject(AuthService);
    apiService = inject(ApiService);

    // Explicitly declare all properties
    displayedColumns: string[] = ['Email', 'Type', 'Name', 'Report', 'CreateDate', 'Score', 'Status', 'Phase', 'UBO', 'Actions'];
    diligences: any[] = [];
    loading = false;
    totalItems = 0;
    currentPage = 0;
    pageSize = 20;
    pageSizeOptions: number[] = [5, 10, 20, 50, 100];
    currentSort: Sort = { active: 'created_at', direction: 'desc' };
    entityTypes: string[] = ['Individual', 'Company'];
    selectedEntityType: string | null = null;
    packages = packages;
    selectedPackage: string | null = null;
    separatorKeysCodes: number[] = [ENTER, COMMA];
    emailCtrl = new FormControl('');
    filteredEmails: Observable<string[]>;
    selectedEmails: string[] = [];
    allEmails: string[] = [];
    searchTerm = '';
    private searchSubject = new Subject<string>();

    tabs: TabInfo[] = [
        {
            label: 'Processing',
            icon: 'schedule',
            phases: [1, 2, 3, 4],
            color: 'text-amber-500'
        },
        {
            label: 'Pending Approval',
            icon: 'pending',
            phases: [5],
            color: 'text-blue-500'
        },
        {
            label: 'Approved',
            icon: 'check_circle',
            phases: [6],
            color: 'text-green-500'
        }
    ];

    selectedTab = 1;

    constructor() {
        this.filteredEmails = this.emailCtrl.valueChanges.pipe(
            startWith(null),
            map((email: string | null) => email ? this._filter(email) : [])
        );
    }

    ngOnInit(): void {
        this.setupSearchSubscription();
        this.setupEmailAutocomplete();
        this.loadDiligences();
        this.loadAllEmails();
    }

    private setupEmailAutocomplete(): void {
        this.filteredEmails = this.emailCtrl.valueChanges.pipe(
            startWith(null),
            map((email: string | null) => email ? this._filter(email) : this.allEmails.slice())
        );
    }

    private _filter(value: string): string[] {
        const filterValue = value.toLowerCase();
        return this.allEmails.filter(email => email.toLowerCase().includes(filterValue));
    }

    loadAllEmails(): void {
        this.apiService.getFilterOptions().subscribe(
            response => {
                this.allEmails = response.users;
                // Update filtered emails after loading the options
                this.setupEmailAutocomplete();
            },
            error => {
                console.error('Error loading email options:', error);
                // TODO: Add error handling UI
            }
        );
    }

    addEmail(event: any): void {
        const value = (event.value || '').trim();
        if (value) {
            this.selectedEmails.push(value);
            this.loadDiligences();
        }
        this.emailCtrl.setValue(null);
    }

    removeEmail(email: string): void {
        const index = this.selectedEmails.indexOf(email);
        if (index >= 0) {
            this.selectedEmails.splice(index, 1);
            this.loadDiligences();
        }
    }

    selected(event: any): void {
        this.selectedEmails.push(event.option.viewValue);
        this.emailCtrl.setValue(null);
        this.loadDiligences();
    }

    private setupSearchSubscription(): void {
        this.searchSubject.pipe(
            debounceTime(300),
            distinctUntilChanged()
        ).subscribe(() => {
            this.loadDiligences();
        });
    }

    onSearch(event: any): void {
        this.searchSubject.next(event.target.value);
    }

    loadDiligences(): void {
        this.loading = true;
        
        const queryParams: DiligenceQueryParams = {
            page: this.currentPage + 1,
            limit: this.pageSize,
            sort: {
                field: this.currentSort.active === 'CreateDate' ? 'created_at' : 'updated_at',
                order: (this.currentSort.direction || 'desc') as 'asc' | 'desc'
            },
            filters: {
                phase: this.currentPhase,
                entityType: this.selectedEntityType || undefined,
                user_email: this.selectedEmails.length ? this.selectedEmails : undefined,
                report: this.selectedPackage || undefined
            },
            search: this.searchTerm || undefined
        };

        this.auth.user$.pipe(
            switchMap(user => {
                if (user?.email) {
                    return this.apiService.getDiligencesByPhase(queryParams);
                } else {
                    throw new Error('user not logged in');
                }
            })
        ).subscribe(
            response => {
                this.loading = false;
                this.diligences = response.data;
                this.totalItems = response.metadata.total;
            },
            error => {
                this.loading = false;
                console.error('Error loading diligences:', error);
            }
        );
    }

    onPageChange(event: PageEvent): void {
        this.currentPage = event.pageIndex;
        this.pageSize = event.pageSize;
        this.loadDiligences();
    }

    onSortChange(sort: Sort): void {
        this.currentSort = sort;
        this.loadDiligences();
    }

    onTabChange(index: number): void {
        this.selectedTab = index;
        this.loadDiligences();
    }

    onEntityTypeChange(entityType: string): void {
        this.selectedEntityType = entityType;
        this.loadDiligences();
    }

    onPackageChange(packageCode: string): void {
        this.selectedPackage = packageCode;
        this.loadDiligences();
    }

    openDiligence(uuid: string): void {
        window.open(`/dueDiligence/${uuid}`, '_blank');
    }

    // Update the currentPhase property
    get currentPhase(): number[] {
        return this.tabs[this.selectedTab].phases;
    }
}