import { CommonModule } from '@angular/common';
import { Component, Input, OnInit, AfterViewInit, ViewChildren, QueryList } from '@angular/core';
import { MatTableModule, MatTableDataSource } from '@angular/material/table';
import { MatPaginatorModule, MatPaginator } from '@angular/material/paginator';
import { PDFDocument } from 'src/app/core/interfaces/pdf-document.interface';
import { PdfTableService } from 'src/app/core/services/pdf-table.service';

@Component({
  selector: 'app-legal-summary',
  templateUrl: './legal-summary.component.html',
  styleUrls: ['./legal-summary.component.scss'],
  standalone: true,
  imports: [CommonModule, MatTableModule, MatPaginatorModule]
})
export class LegalSummaryComponent implements OnInit, AfterViewInit {
  @Input()
  activeSuits: any = {};

  @Input()
  releasedSuits: any = {};

  @Input()
  activeJudgements: any = {};

  @Input()
  printMode: boolean = false;

  @Input()
  releasedJudgements: any = {};

  @ViewChildren(MatPaginator) paginator = new QueryList<MatPaginator>();

  constructor(private pdfTableService: PdfTableService) {}

  displayedColumns: string[] = ['date', 'filingType', 'caseNumber', 'amount', 'plaintiff'];
  dataSourceActiveSuits!: MatTableDataSource<any>;
  dataSourceReleasedSuits!: MatTableDataSource<any>;
  dataSourceActivejudgement!: MatTableDataSource<any>;
  dataSourceReleasedjudgements!: MatTableDataSource<any>;

  ngOnInit(): void {
    this.dataSourceActiveSuits = new MatTableDataSource<any>(this.activeSuits || []);
    this.dataSourceReleasedSuits = new MatTableDataSource<any>(this.releasedSuits || []);
    this.dataSourceActivejudgement = new MatTableDataSource<any>(this.activeJudgements || []);
    this.dataSourceReleasedjudgements = new MatTableDataSource<any>(this.releasedJudgements || []);
  }

  ngAfterViewInit() {
    this.dataSourceActiveSuits.paginator = this.paginator.toArray()[0];
    this.dataSourceReleasedSuits.paginator = this.paginator.toArray()[1];
    this.dataSourceActivejudgement.paginator = this.paginator.toArray()[2];
    this.dataSourceReleasedjudgements.paginator = this.paginator.toArray()[3];
  }

  static renderToPdf(doc: PDFDocument, data: any): void {
    // Add summary table
    const summaryHeaders = ['', 'Total', 'Active', 'Released'];
    const summaryRows = [
      ['Number Of Suits', 
       (data.activeSuits?.length || 0) + (data.releasedSuits?.length || 0),
       data.activeSuits?.length || 0,
       data.releasedSuits?.length || 0
      ],
      ['Number Of Judgements',
       (data.activeJudgements?.length || 0) + (data.releasedJudgements?.length || 0),
       data.activeJudgements?.length || 0,
       data.releasedJudgements?.length || 0
      ]
    ];

    PdfTableService.drawTable(doc, summaryHeaders, summaryRows);
    doc.moveDown(30);

    // Add active suits
    if (data.activeSuits?.length) {
      doc.fontSize(12)
         .font('Helvetica-Bold')
         .text('Active Suits', { align: 'left' })
         .moveDown(15);
      
      const headers = ['Date', 'Filing Type', 'Case Number', 'Amount', 'Plaintiff'];
      const rows = data.activeSuits.map((suit: any) => [
        suit.date ? new Date(suit.date).toLocaleDateString() : '',
        suit.filingType || '',
        suit.caseNumber || '',
        suit.amount || '',
        suit.plaintiff || ''
      ]);

      PdfTableService.drawTable(doc, headers, rows);
      doc.moveDown(2);
    }

    // Add released suits
    if (data.releasedSuits?.length) {
      doc.fontSize(12)
         .font('Helvetica-Bold')
         .text('Released Suits', { align: 'left' })
         .moveDown(15);
      
      const headers = ['Date', 'Filing Type', 'Case Number', 'Amount', 'Plaintiff'];
      const rows = data.releasedSuits.map((suit: any) => [
        suit.date ? new Date(suit.date).toLocaleDateString() : '',
        suit.filingType || '',
        suit.caseNumber || '',
        suit.amount || '',
        suit.plaintiff || ''
      ]);

      PdfTableService.drawTable(doc, headers, rows);
      doc.moveDown(30);
    }

    // Add active judgements
    if (data.activeJudgements?.length) {
      doc.fontSize(12)
         .font('Helvetica-Bold')
         .text('Active Judgements', { align: 'left' })
         .moveDown(15);
      
      const headers = ['Date', 'Filing Type', 'Case Number', 'Amount', 'Plaintiff'];
      const rows = data.activeJudgements.map((judgement: any) => [
        judgement.date ? new Date(judgement.date).toLocaleDateString() : '',
        judgement.filingType || '',
        judgement.caseNumber || '',
        judgement.amount || '',
        judgement.plaintiff || ''
      ]);

      PdfTableService.drawTable(doc, headers, rows);
      doc.moveDown(30);
    }

    // Add released judgements
    if (data.releasedJudgements?.length) {
      doc.fontSize(12)
         .font('Helvetica-Bold')
         .text('Released Judgements', { align: 'left' })
         .moveDown(15);
      
      const headers = ['Date', 'Filing Type', 'Case Number', 'Amount', 'Plaintiff'];
      const rows = data.releasedJudgements.map((judgement: any) => [
        judgement.date ? new Date(judgement.date).toLocaleDateString() : '',
        judgement.filingType || '',
        judgement.caseNumber || '',
        judgement.amount || '',
        judgement.plaintiff || ''
      ]);

      PdfTableService.drawTable(doc, headers, rows);
      doc.moveDown(30);
    }
  }
}

