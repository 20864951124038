import { Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReportFieldDirective } from 'src/app/core/directives/report-field.directive';
import { PDFDocument } from 'src/app/core/interfaces/pdf-document.interface';
import { PdfTableService } from 'src/app/core/services/pdf-table.service';

@Component({
  selector: 'app-payment-data',
  templateUrl: './payment-data.component.html',
  standalone: true,
  imports: [CommonModule]
})
export class PaymentDataComponent {
  @Input() printMode: boolean = false;
  @Input() outstandingExperiencesInTheLast6Months: any;

  constructor(private pdfTableService: PdfTableService) {}

  static renderToPdf(doc: PDFDocument, data: any): void {
    if (data.outstandingExperiencesInTheLast6Months) {
      doc.fontSize(14)
         .font('Helvetica-Bold')
         .text('Outstanding Experiences in The Last 6 Months', { align: 'left' })
         .moveDown();

      const headers = ['Month', 'Value'];
      const rows = Object.entries(data.outstandingExperiencesInTheLast6Months)
        .map(([month, value]) => [month, value]);

      PdfTableService.drawTable(doc, headers, rows);
      doc.moveDown();
    }
  }
}
