import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';
import { MatExpansionModule } from '@angular/material/expansion';
import { ReportFieldDirective } from 'src/app/core/directives/report-field.directive';
import { PDFDocument } from 'src/app/core/interfaces/pdf-document.interface';
import { PdfTableService } from 'src/app/core/services/pdf-table.service';

@Component({
  selector: 'app-businnes-affiliations',
  templateUrl: './businnes-affiliations.component.html',
  styleUrls: ['./businnes-affiliations.component.scss'],
  standalone: true,
  imports: [CommonModule, MatExpansionModule, ReportFieldDirective],
})
export class BusinnesAffiliationsComponent {
  @Input() printMode: boolean = false;

  constructor(private pdfTableService: PdfTableService) {}

  getCurrentCharges(positions: any[]) {
    return positions?.filter(pos => pos?.positionType === 'Current');
  }

  getCeasedCharges(positions: any[]) {
    return positions?.filter(pos => pos?.positionType === 'Previous');
  }

  getInactiveAndPreviousCharges(previous: any[], inactive: any[]) {
    return previous?.concat(inactive);
  }

  static renderToPdf(doc: PDFDocument, data: any): void {
    // Skip rendering if no business affiliations data
    if (!data.businessAffiliations?.current?.length && 
        !data.businessAffiliations?.previous?.length && 
        !data.businessAffiliations?.inactive?.length && 
        !data.businessAffiliations?.general?.length) {
      return;
    }

    // Start new page for business affiliations
    doc.addPage();

    const pageWidth = 612;
    const marginLeft = 50;
    const marginRight = 50;
    const contentWidth = pageWidth - marginLeft - marginRight;

    // Render current business affiliations
    if (data.businessAffiliations?.current?.length) {
      doc.rect(marginLeft, doc.y, contentWidth, 30).fill('#172554');
      doc.fontSize(14)
         .font('Helvetica-Bold')
         .fill('#ffffff')
         .text('Current Business Affiliations', { 
           align: 'center',
           width: contentWidth
         })
         .moveDown(15);
      
      data.businessAffiliations.current.forEach((affiliation: any) => {
        BusinnesAffiliationsComponent.renderAffiliation(doc, affiliation);
      });
    }

    // Render past business affiliations (combining previous and inactive)
    const pastAffiliations = [
      ...(data.businessAffiliations?.previous || []),
      ...(data.businessAffiliations?.inactive || [])
    ];

    if (pastAffiliations.length) {
      doc.moveDown(5);
      doc.rect(marginLeft, doc.y, contentWidth, 30).fill('#172554');
      doc.fontSize(14)
         .font('Helvetica-Bold')
         .fill('#ffffff')
         .text('Past Business Affiliations', { 
           align: 'center',
           width: contentWidth
         })
         .moveDown(20);
      
      pastAffiliations.forEach((affiliation: any) => {
        BusinnesAffiliationsComponent.renderAffiliation(doc, affiliation);
      });
    }

    // Render general business affiliations
    if (data.businessAffiliations?.general?.length) {
      doc.moveDown(10);
      doc.rect(marginLeft, doc.y, contentWidth, 30).fill('#172554');
      doc.fontSize(14)
         .font('Helvetica-Bold')
         .fill('#ffffff')
         .text('Business Affiliations', { 
           align: 'center',
           width: contentWidth
         })
         .moveDown(20);
      
      data.businessAffiliations.general.forEach((company: any) => {
        doc.fontSize(12)
           .font('Helvetica-Bold')
           .text(company.name, { align: 'left' })
           .moveDown(10);

        const headers = ['Field', 'Value'];
        const rows = [
          ['Address', company.address || ''],
          ['Country', company.country || '']
        ];

        PdfTableService.drawTable(doc, headers, rows);
        doc.moveDown(15);
      });
    }
  }

  // Helper function to render a single affiliation
  private static renderAffiliation(doc: PDFDocument, affiliation: any): void {
    doc.fontSize(12)
       .font('Helvetica-Bold')
       .text(affiliation.companyName, { align: 'left' })
       .moveDown(15);
    
    const headers = ['Field', 'Value'];
    const rows = [
      ['Status', affiliation.status || ''],
      ['Legal Address', affiliation.address?.simpleValue || ''],
      ['Tax Code', affiliation.taxCode || affiliation.additionalData?.taxCode || ''],
      ['Activity Start Date', affiliation.activityStartDate ? new Date(affiliation.activityStartDate).toLocaleDateString() : ''],
      ['CCIAA/REA', affiliation.cciaaRea || ''],
      ['Activity', affiliation.activity?.description || ''],
      ['Legal Form', affiliation.legalForm?.description || ''],
      ['Legal Email', affiliation.legalEmail || '']
    ];

    PdfTableService.drawTable(doc, headers, rows);

    // Add current charges if any
    const currentCharges = BusinnesAffiliationsComponent.getCurrentCharges(affiliation.positions);
    if (currentCharges?.length) {
      doc.moveDown()
         .fontSize(12)
         .font('Helvetica-Bold')
         .text('Current Charges', { align: 'left' })
         .moveDown(10);

      const chargeHeaders = ['Position', 'Registration Date', 'Protocol Number', 'Protocol Date'];
      const chargeRows = currentCharges.map(charge => [
        charge.positionDetail || '',
        charge.registrationDate ? new Date(charge.registrationDate).toLocaleDateString() : '',
        charge.protocolNumber || '',
        charge.protocolDate ? new Date(charge.protocolDate).toLocaleDateString() : ''
      ]);

      PdfTableService.drawTable(doc, chargeHeaders, chargeRows);
    }

    // Add ceased charges if any
    const ceasedCharges = BusinnesAffiliationsComponent.getCeasedCharges(affiliation.positions);
    if (ceasedCharges?.length) {
      doc.moveDown(10)
         .fontSize(12)
         .font('Helvetica-Bold')
         .text('Ceased Charges', { align: 'left' })
         .moveDown(10);

      const chargeHeaders = ['Position', 'Registration Date', 'Protocol Number', 'Protocol Date'];
      const chargeRows = ceasedCharges.map(charge => [
        charge.positionDetail || '',
        charge.registrationDate ? new Date(charge.registrationDate).toLocaleDateString() : '',
        charge.protocolNumber || '',
        charge.protocolDate ? new Date(charge.protocolDate).toLocaleDateString() : ''
      ]);

      PdfTableService.drawTable(doc, chargeHeaders, chargeRows);
    }

    doc.moveDown(15);
  }

  private static getCurrentCharges(positions: any[]): any[] {
    if (!positions) return [];
    return positions.filter(position => !position.endDate);
  }

  private static getCeasedCharges(positions: any[]): any[] {
    if (!positions) return [];
    return positions.filter(position => position.endDate);
  }
}
