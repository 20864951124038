import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';
import { MatExpansionModule } from '@angular/material/expansion';
import { ReportFieldDirective } from 'src/app/core/directives/report-field.directive';
import { PDFDocument } from 'src/app/core/interfaces/pdf-document.interface';
import { PdfTableService } from 'src/app/core/services/pdf-table.service';

@Component({
  selector: 'app-directors',
  templateUrl: './directors.component.html',
  styleUrls: ['./directors.component.scss'],
  standalone: true,
  imports: [CommonModule, MatExpansionModule, ReportFieldDirective],
})
export class DirectorsComponent {
  @Input() printMode: boolean = false;
  @Input() directors: any[] = [];
  @Input() shareholders: any[] = [];

  constructor(private pdfTableService: PdfTableService) {}

  isArray(value: any): boolean {
    return Array.isArray(value);
  }

  // static renderToPdf(doc: PDFDocument, data: any): void {
  //   // Add directors section
  //   if (data.directors?.length) {
  //     doc.fontSize(14)
  //        .font('Helvetica-Bold')
  //        .text('Directors', { align: 'left' })
  //        .moveDown(10);

  //     data.directors.forEach((director: any) => {
  //       doc.fontSize(12)
  //          .font('Helvetica-Bold')
  //          .text(director.name, { align: 'left' })
  //          .moveDown(10);

  //       // Add director details
  //       const headers = ['Field', 'Value'];
  //       const rows = [
  //         ['Name', director.name || ''],
  //         ['Position', director.position || ''],
  //         ['Start Date', director.startDate ? new Date(director.startDate).toLocaleDateString() : ''],
  //         ['End Date', director.endDate ? new Date(director.endDate).toLocaleDateString() : ''],
  //         ['Tax Code', director.taxCode || ''],
  //         ['Address', director.address || '']
  //       ];

  //       PdfTableService.drawTable(doc, headers, rows);

  //       // Add positions if any
  //       if (director.positions?.length) {
  //         doc.moveDown(10)
  //            .fontSize(12)
  //            .font('Helvetica-Bold')
  //            .text('Positions', { align: 'left' })
  //            .moveDown(10);

  //         const positionHeaders = ['Position', 'Start Date', 'End Date', 'Status'];
  //         const positionRows = director.positions.map((position: any) => [
  //           position.positionName || '',
  //           position.startDate ? new Date(position.startDate).toLocaleDateString() : '',
  //           position.endDate ? new Date(position.endDate).toLocaleDateString() : '',
  //           position.status || ''
  //         ]);

  //         PdfTableService.drawTable(doc, positionHeaders, positionRows);
  //       }

  //       doc.moveDown(15);
  //     });
  //   }

  //   // Add shareholders section
  //   if (data.shareholders?.length) {
  //     doc.moveDown(10)
  //        .fontSize(14)
  //        .font('Helvetica-Bold')
  //        .text('Shareholders', { align: 'left' })
  //        .moveDown(10);

  //     data.shareholders.forEach((shareholder: any) => {
  //       doc.fontSize(12)
  //          .font('Helvetica-Bold')
  //          .text(shareholder.name, { align: 'left' })
  //          .moveDown(10);

  //       const headers = ['Field', 'Value'];
  //       const rows = [
  //         ['Name', shareholder.name || ''],
  //         ['Share Type', shareholder.shareType || ''],
  //         ['Share Value', shareholder.shareValue || ''],
  //         ['Share Percentage', shareholder.sharePercentage || ''],
  //         ['Tax Code', shareholder.taxCode || ''],
  //         ['Address', shareholder.address || '']
  //       ];

  //       PdfTableService.drawTable(doc, headers, rows);
  //       doc.moveDown(15);
  //     });
  //   }
  // }
}
