<div class="min-h-full">
    <app-navbar></app-navbar>
    <div class="border-2 border-grey border-t-cyan-700 m-5">
        <h1>My Account</h1>

        <div *ngIf="auth.user$ | async as user">
            EMAIL: {{user.email}}
        </div>

        <mat-tab-group>
            <mat-tab label="My reports">
                <div class="flex justify-center pt-5">
                    <mat-spinner *ngIf="loading"></mat-spinner>
                </div>
                <mat-table [dataSource]="diligences" class="mat-elevation-z8">
                    <ng-container matColumnDef="Email">
                        <mat-header-cell *matHeaderCellDef> Email </mat-header-cell>
                        <mat-cell *matCellDef="let diligence"> {{ diligence.user_email }} </mat-cell>
                    </ng-container>

                    <!-- TODO Aggiungere company name alle diligences -->
                    <ng-container matColumnDef="Name">
                        <mat-header-cell *matHeaderCellDef> Name </mat-header-cell>
                        <mat-cell *matCellDef="let diligence"> {{ diligence.name }} </mat-cell>
                    </ng-container>

                    <ng-container matColumnDef="Type">
                        <mat-header-cell *matHeaderCellDef> Type </mat-header-cell>
                        <mat-cell *matCellDef="let diligence"> {{ diligence.type }} </mat-cell>
                    </ng-container>

                    <ng-container matColumnDef="Report">
                        <mat-header-cell *matHeaderCellDef> Report </mat-header-cell>
                        <mat-cell *matCellDef="let diligence"> {{ getDiligenceName(diligence) }} </mat-cell>
                    </ng-container>

                    <ng-container matColumnDef="CreateDate">
                        <mat-header-cell *matHeaderCellDef> Create Date </mat-header-cell>
                        <mat-cell *matCellDef="let diligence"> {{ diligence.created_at | date}} </mat-cell>
                    </ng-container>

                    <ng-container matColumnDef="Score">
                        <mat-header-cell *matHeaderCellDef> Score </mat-header-cell>

                        <mat-cell *matCellDef="let diligence">
                            <ng-container *ngIf="diligence.phase == 6;">
                                {{ diligence.rep_score }}
                            </ng-container>
                        </mat-cell>
                    </ng-container>

                    <ng-container matColumnDef="Actions">
                        <mat-header-cell *matHeaderCellDef></mat-header-cell>
                        <mat-cell *matCellDef="let diligence">
                            <ng-container *ngIf="diligence.phase == 6; else pending">
                                <button (click)="openDiligence(diligence.uuid)">
                                    <mat-icon class="material-symbols-outlined">open_in_new</mat-icon>
                                </button>
                            </ng-container>
                            <ng-template #pending>
                                <mat-chip>Pending</mat-chip>
                            </ng-template>
                        </mat-cell>
                    </ng-container>

                    <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
                    <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>
                </mat-table>
            </mat-tab>
        </mat-tab-group>
    </div>
</div>

<app-footer></app-footer>