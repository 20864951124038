<nav class="h-24 flex items-center justify-between -mt-5 shadow px-2">
    <div class="h-full cursor-pointer w-64 relative" (click)="navigateToHome()">
        <div class="h-full flex items-center justify-center p-1">
            <img src="../../../assets/TevunaLogo.svg" class="w-auto h-full"/>
        </div>
    </div>

    <div class="p-3 flex justify-center items-center flex-nowrap">
        <div *ngIf="auth.user$ | async as user" class="flex justify-center items-center">
            <mat-icon>person</mat-icon>
            <div>{{user.email}} <span *ngIf="isAdmin$ | async">
                <button mat-stroked-button [matMenuTriggerFor]="adminMenu" color="primary">      
                    <span>ADMIN</span>
                    <mat-icon>arrow_drop_down</mat-icon>
                </button>
                <mat-menu #adminMenu="matMenu">
                    <a mat-menu-item [routerLink]="['/admin']" routerLinkActive="active">
                        <mat-icon>description</mat-icon>
                        <span>Reports Dashboard</span>
                    </a>
                    <a mat-menu-item [routerLink]="['/accounts']" routerLinkActive="active">
                        <mat-icon>group</mat-icon>
                        <span>Manage Accounts</span>
                    </a>
                </mat-menu>
            </span></div>
        </div>
        <div class="p-3 flex flex-col items-center">
            <div *ngIf="auth.isAuthenticated$ | async; else loggedOut">
                <button mat-stroked-button color="primary" (click)="auth.logout()">
                    logout ⬅️
                </button>
            </div>
            <ng-template #loggedOut>
                <button mat-stroked-button color="primary" (click)="auth.loginWithRedirect()">
                    Log in ➡️
                </button>
            </ng-template>
        </div>

        <button mat-icon-button [matMenuTriggerFor]="menu" aria-label="Example icon-button with a menu">
            <mat-icon>more_vert</mat-icon>
        </button>
        <mat-menu #menu="matMenu">
            <button mat-menu-item (click)="navigateToMyAccount()" [disabled]="!(auth.user$ | async)?.email">
                <mat-icon>person</mat-icon>
                <span>My account</span>
            </button>
            <button mat-menu-item (click)="navigateToHome()">
                <mat-icon>home</mat-icon>
                <span>Home</span>
            </button>
        </mat-menu>
    </div>
</nav>