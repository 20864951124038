import * as jsonata from 'jsonata';

export interface DiligenceData {
  creditsafeData?: {
    report?: any;
  };
  type: string;
  essentialData?: {
    individuals?: any[];
    companies?: any[];
  };
  country?: string;
  uniqueCode?: string;
  repScore?: number;
  repSumm?: string;
  critIssueSummary?: string;
  critIssueDetails?: string;
}

export interface TransformedDiligenceData {
  generalInfo: {
    companyName: string | null;
    vatNumber: string | null;
    registrationNumber: string | null;
    incorporationDate: string | null;
    legalForm: string | null;
    taxCode: string | null;
    otherLegalName: string | null;
    charterNumber: string | null;
    establishmentDate: string | null;
    companyType: string | null;
    sicDescription: string | null;
    companySize: string | null;
    individualSize: string | null;
  };
  contactInfo: {
    address: string | null;
    phoneNumber: string | null;
    legalEmail: string | null;
    locationType: string | null;
    website: string | null;
    corporatePrimaryAddress: string | null;
    country: string | null;
  };
  directorDetails: {
    name: string | null;
    address: { street: string | null; city: string | null; province: string | null; postalCode: string | null } | null;
  };
  riskScore: {
    score: number | null;
    creditLimit: number | null;
    creditLimitRisk: string | null;
  };
  riskPercentiles: {
    usPercentile: number | null;
    industryPercentile: number | null;
    sicCode: string | null;
  };
  scoreHistory: {
    dates: (string | null)[] | null;
    industryValues: (number | null)[] | null;
    companyValues: (number | null)[] | null;
  };
  financials: {
    yearEndDate: string | null;
    revenue: number | null;
    profitBeforeTax: number | null;
    retainedProfit: number | null;
    totalAssets: number | null;
    totalLiabilities: number | null;
    workingCapital: number | null;
    netWorth: number | null;
    totalShareholdersEquity: number | null;
  };
  industry: {
    mainActivityCode: string | null;
    mainActivityDescription: string | null;
    mainActivityClassification: string | null;
    saeCode: string | null;
    raeCode: string | null;
    carriedOutActivity: string | null;
  };
  groupInfo: {
    companyName: string | null;
    country: string | null;
    csid: string | null;
    companyAnalysis: string | null;
  };
  ultimateHolding: {
    safeNumber: string | null;
    name: string | null;
    country: string | null;
    taxCode: string | null;
  };
  directors: Array<{
    name: string | null;
    id: string | null;
    gender: string | null;
    dateOfBirth: string | null;
    placeOfBirth: string | null;
    positions: Array<{
      positionName: string | null;
      appointmentDate: string | null;
      durationType: string | null;
    }>;
    address: string | null;
    additionalData: {
      hasProtesti: boolean | null;
      hasSeverePrejudicials: boolean | null;
    };
  }>;
  shareholders: Array<{
    name: string | null;
    id: string | null;
    shareClasses: Array<{
      type: string | null;
      value: number | null;
      percentage: number | null;
    }>;
  }>;
  paymentData: any | null;
  tradePaymentDashboard: any | null;
  tradePaymentInfo: {
    groupTradePaymentInformation: any | null;
    paidGroupTradePaymentInformation: any | null;
  };
  legalFilings: {
    activeSuits: Array<{
      filedDate: string | null;
      filingType: string | null;
      filingNumber: string | null;
      amount: number | null;
      plaintiff: string | null;
      status: string | null;
    }>;
    releasedSuits: Array<{
      filedDate: string | null;
      filingType: string | null;
      filingNumber: string | null;
      amount: number | null;
      plaintiff: string | null;
      status: string | null;
    }>;
    activeJudgements: Array<{
      filedDate: string | null;
      filingType: string | null;
      filingNumber: string | null;
      amount: number | null;
      plaintiff: string | null;
      status: string | null;
    }>;
    releasedJudgements: Array<{
      filedDate: string | null;
      filingType: string | null;
      filingNumber: string | null;
      amount: number | null;
      plaintiff: string | null;
      status: string | null;
    }>;
  };
  commentaries: (string | null)[] | null;
  businessAffiliations: {
    current: Array<{
      companyName: string | null;
      taxCode: string | null;
      positions: Array<{
        positionName: string | null;
        appointmentDate: string | null;
        durationType: string | null;
      }>;
      status: string | null;
      address: string | null;
      activity: string | null;
      legalForm: string | null;
      activityStartDate: string | null;
      legalEmail: string | null;
    }>;
    previous: Array<{
      companyName: string | null;
      taxCode: string | null;
      positions: Array<{
        positionName: string | null;
        appointmentDate: string | null;
        durationType: string | null;
      }>;
      status: string | null;
      address: string | null;
      activity: string | null;
      legalForm: string | null;
      activityStartDate: string | null;
      legalEmail: string | null;
    }>;
    inactive: Array<{
      companyName: string | null;
      taxCode: string | null;
      positions: Array<{
        positionName: string | null;
        appointmentDate: string | null;
        durationType: string | null;
      }>;
      status: string | null;
      address: string | null;
      activity: string | null;
      legalForm: string | null;
      activityStartDate: string | null;
      legalEmail: string | null;
    }>;
  };
  tevunaReport: {
    repScore: number | null;
    repSumm: string | null;
    critIssueSummary: string | null;
    critIssueDetails: string | null;
  };
}

async function queryData(data: any, expression: string): Promise<any> {
  try {
    const resolvedData = await Promise.resolve(data);
    return jsonata(expression).evaluate(resolvedData);
  } catch (error) {
    console.error(`Error evaluating expression: ${expression}`, error);
    return null;
  }
}

async function resolveTransformer(transformer: object, data: any): Promise<TransformedDiligenceData> {
  const resolvedObject: any = {};
  const resolvedData = await Promise.resolve(data);

  async function resolveField(expression: string | object): Promise<any> {
    if (typeof expression === 'object' && expression != null) {
      const nestedResolved: any = {};
      for (const [nestedKey, nestedExpression] of Object.entries(expression)) {
        nestedResolved[nestedKey] = await resolveField(nestedExpression);
      }
      return nestedResolved;
    }

    try {
      const compiledExpression = jsonata(expression);
      return await compiledExpression.evaluate(resolvedData);
    } catch (error) {
      console.error(`Error resolving expression "${expression}": ${(error as Error).message}`);
      return null;
    }
  }

  for (const [key, expression] of Object.entries(transformer)) {
    resolvedObject[key] = await resolveField(expression);
  }

  return resolvedObject;
}

export async function transformDiligenceData2(originalData: DiligenceData): Promise<{ resolvedData: any; transformer: object }> {
  
  const transformer = {
    generalInfo: {
      "companyName": 'type = "COMPANY" ? essentialData.companies[0].name : essentialData.individuals[0].name',
      "monitoredEntities": 'type = "COMPANY" ? [essentialData.companies.name, essentialData.individuals.name] : [essentialData.individuals.name]',
      "vatNumber": 'creditsafeData.report.alternateSummary.vatRegistrationNumber',
      "registrationNumber": 'creditsafeData.report.companySummary.companyRegistrationNumber',
      "incorporationDate": 'creditsafeData.report.alternateSummary.incorporationDate',
      "legalForm": 'creditsafeData.report.alternateSummary.legalForm',
      "taxCode": 'creditsafeData.report.alternateSummary.taxCode',
      "otherLegalName": 'creditsafeData.report.additionalInformation.otherCorporateRecords[0].otherLegalName',
      "charterNumber": 'creditsafeData.report.additionalInformation.otherCorporateRecords[0].sosCharterNumber',
      "establishmentDate": 'creditsafeData.report.additionalInformation.otherCorporateRecords[0].incorporationDate',
      "companyType": 'creditsafeData.report.companyIdentification.basicInformation.legalForm.description',
      "sicDescription": 'creditsafeData.report.companyIdentification.basicInformation.principalActivity.description',
      "companySize": 'type = "COMPANY" ? essentialData.companies[0].companySize : essentialData.individuals[0].individualSize',
      "individualSize": 'type = "INDIVIDUAL" ? essentialData.individuals[0].individualSize : essentialData.companies[0].companySize'
    },
    paymentData: 'creditsafeData.report.paymentData.outstandingExperiencesInTheLast6Months',
    directorDetails: {
      "name": "creditsafeData.report.directorDetails.name",
      "dateOfBirth": "creditsafeData.report.directorDetails.dateOfBirth",
      "gender": "creditsafeData.report.directorDetails.gender",
      "address": `creditsafeData.report.directorDetails.address.{
        "street": street,
        "simpleValue": simpleValue,
        "city": city,
        "province": province,
        "postalCode": postalCode
      }`,
      "addressOfBirth": `creditsafeData.report.directorDetails.addressOfBirth.{
        "street": street,
        "simpleValue": simpleValue,
        "city": city,
        "id": id,
        "province": province,
        "postalCode": postalCode
      }`
    },
    contactInfo: {
      "address": 'creditsafeData.report.alternateSummary.address ? creditsafeData.alternateSummary.address : creditsafeData.additionalInformation.otherCorporateRecords[0].primaryAddress.simpleValue',
      "phoneNumber": 'creditsafeData.report.alternateSummary.telephone',
      "legalEmail": 'creditsafeData.report.alternateSummary.emailAddresses',
      "locationType": 'creditsafeData.report.additionalInformation.misc.locationType',
      "website": 'creditsafeData.report.contactInformation.websites[0]',
      "corporatePrimaryAddress": 'creditsafeData.report.additionalInformation.misc.corporatePrimaryAddress',
      "country": 'creditsafeData.report.alternateSummary.country ? creditsafeData.alternateSummary.country : creditsafeData.country'
    },
    riskScore: {
      "score": 'creditsafeData.report.creditScore.currentCreditRating.providerValue.value',
      "creditLimit": 'creditsafeData.report.creditScore.currentCreditRating.creditLimit.value',
      "creditLimitRisk": 'creditsafeData.report.creditScore.currentCreditRating.commonDescription'
    },
    riskPercentiles: {
      "usPercentile": 'creditsafeData.report.additionalInformation.ratingPercentiles.usPercentile.percentile',
      "industryPercentile": 'creditsafeData.report.additionalInformation.ratingPercentiles.industryPercentile.percentile',
      "sicCode": 'creditsafeData.report.additionalInformation.ratingPercentiles.industryPercentile.sicCode'
    },
    scoreHistory: {
      "dates": 'creditsafeData.report.additionalInformation.ratingHistory.date',
      "industryValues": 'creditsafeData.report.additionalInformation.creditRatingHistory.industryValue',
      "companyValues": 'creditsafeData.report.additionalInformation.ratingHistory.companyValue'
    },
    sanctions: {
      "screening": `{
        "companies": [sancIssueSummary.screening.companies[comment.target.sanctions].comment.target],
        "individuals": [sancIssueSummary.screening.individuals[comment.target.sanctions].comment.target]
      }`,
      "summary": 'sancIssueSummary.summary',
      "billOfLading": `{
        "companies": [sancIssueSummary.\`risk-s1\`.companies[comment != null]],
        "individuals": [sancIssueSummary.\`risk-s1\`.individuals[comment != null]]
      }`,
      "usaRelationship": `{
        "companies": [sancIssueSummary.\`risk-usa-relationship\`.companies[summary != null]],
        "individuals": [sancIssueSummary.\`risk-usa-relationship\`.individuals[summary != null]]
      }`,
      "media": `{
        "companies": [sancIssueSummary.\`risk-s2\`.companies[summary != null], sancIssueSummary.\`risk-s3\`.companies[summary != null]],
        "individuals": [sancIssueSummary.\`risk-s2\`.individuals[summary != null], sancIssueSummary.\`risk-s3\`.individuals[summary != null]]
      }`
    },
    financials: `creditsafeData.report.financialStatements.{
      "yearEndDate": yearEndDate,
      "revenue": profitAndLoss.revenue,
      "profitBeforeTax": profitAndLoss.profitBeforeTax,
      "retainedProfit": profitAndLoss.retainedProfit,
      "totalAssets": balanceSheet.totalAssets,
      "totalLiabilities": balanceSheet.totalLiabilities,
      "workingCapital": otherFinancials.workingCapital,
      "netWorth": otherFinancials.netWorth,
      "totalShareholdersEquity": balanceSheet.totalShareholdersEquity
    }`,
    industry: {
      "mainActivityCode": 'creditsafeData.report.companySummary.mainActivity.code',
      "mainActivityDescription": 'companySummary.mainActivity.description',
      "mainActivityClassification": 'companySummary.mainActivity.classification',
      "saeCode": 'alternateSummary.saeCode',
      "raeCode": 'alternateSummary.raeCode',
      "carriedOutActivity": 'alternateSummary.activityClassifications.description'
    },
    groupInfo: {
      companyName: 'creditsafeData.report.companySummary.businessName ? companySummary.businessName : name',
      country: 'alternateSummary.country ? alternateSummary.country : country',
      csid: originalData.uniqueCode || 'null',
      companyAnalysis: 'additionalInformation.groupAnalysis.currentCompanyAnalysis'
    },
    ultimateHolding: {
      "safeNumber": 'creditsafeData.report.groupStructure.ultimateParent.safeNumber',
      "name": 'groupStructure.ultimateParent.name',
      "country": 'groupStructure.ultimateParent.country',
      "taxCode": 'shareCapitalStructureExtra.shareholders[0].id'
    },
    directors: `[creditsafeData.report.directorsExtra.currentDirectors.{
      "name": name,
      "id": id,
      "gender": gender,
      "dateOfBirth": dateOfBirth,
      "placeOfBirth": placeOfBirth,
      "positions": positions.{
        "positionName": positionName,
        "appointmentDate": additionalData.designationDate,
        "durationType": apptDurationType
      },
      "address": address,
      "additionalData": {
        "hasProtesti": additionalData.hasProtesti,
        "hasSeverePrejudicials": additionalData.hasSeverePrejudicials
      }
    }]`,
    people: `(creditsafeData.report.directorsExtra.currentDirectors or type = "INDIVIDUAL") ? [] : essentialData.individuals`,
    shareholders: `creditsafeData.report.shareCapitalStructureExtra.shareholders.{
      "name": name,
      "id": id,
      "shareClasses": shareClasses.{
        "type": additionalData.shareRights,
        "value": additionalData.quotaValueOwned,
        "percentage": additionalData.quotaValues[0].quotaPercentage
      }
    }`,
    tradePaymentDashboard: 'creditsafeData.report.additionalInformation.extendedPaymentData.miniDashBoard',
    tradePaymentInfo: {
      "groupTradePaymentInformation": 'creditsafeData.report.additionalInformation.extendedPaymentData.groupTradePaymentInformation',
      "paidGroupTradePaymentInformation": 'creditsafeData.report.additionalInformation.extendedPaymentData.paidGroupTradePaymentInformation'
    },
    legalFilings: {
      releasedSuits: `[creditsafeData.report.negativeInformation.suits[status = 'Released'].{
        "filedDate": filedDate,
        "filingType": filingType,
        "filingNumber": filingNumber,
        "amount": amount,
        "plaintiff": plaintiff,
        "status": status
    }]`,
      activeSuits: `[creditsafeData.report.negativeInformation.suits[status = 'Active'].{
        "filedDate": filedDate,
        "filingType": filingType,
        "filingNumber": filingNumber,
        "amount": amount,
        "plaintiff": plaintiff,
        "status": status
    }]`,
      activeJudgements:`[creditsafeData.report.negativeInformation.judgmentDetails[status = 'Active'].{
        "filedDate": filedDate,
        "filingType": filingType,
        "filingNumber": filingNumber,
        "amount": amount,
        "plaintiff": plaintiff,
        "status": status
    }]`,
      releasedJudgements:`[creditsafeData.report.negativeInformation.judgmentDetails[status = 'Released'].{
        "filedDate": filedDate,
        "filingType": filingType,
        "filingNumber": filingNumber,
        "amount": amount,
        "plaintiff": plaintiff,
        "status": status
    }]`
    },
    commentaries: 'creditsafeData.report.alternateSummary.commentaries',
    businessAffiliations: {
      current: `[creditsafeData.report.directorships.current.{
        "companyName": companyName,
        "taxCode": additionalData.taxCode,
        "positions": [positions.{
          "positionName": positionName,
          "appointmentDate": additionalData.designationDate,
          "chargesProceedingDate": additionalData.chargesProceedingDate,
          "positionType": additionalData.positionType,
          "durationType": apptDurationType,
          "positionDetail": additionalData.positionDetail,
          "registrationDate": additionalData.registrationDate,
          "protocolNumber": additionalData.protocolNumber,
          "protocolDate": additionalData.protocolDate
    }],
        "status": status,
        "cciaaRea": additionalData.cciaaRea,
        "address": address,
        "activity": activity,
        "legalForm": legalForm,
        "activityStartDate": additionalData.activityStartDate,
        "legalEmail": additionalData.legalEmail
    }]`,
      general: `(creditsafeData.report.directorships or type = "COMPANY") ? [] : essentialData.companies`,
      previous: `[creditsafeData.report.directorships.previous.{
        "companyName": companyName,
        "taxCode": additionalData.taxCode,
        "positions": [position.{
          "positionName": positionName,
          "appointmentDate": additionalData.designationDate,
          "chargesProceedingDate": additionalData.chargesProceedingDate,
          "positionType": additionalData.positionType,
          "durationType": apptDurationType,
          "positionDetail": additionalData.positionDetail,
          "registrationDate": additionalData.registrationDate,
          "protocolNumber": additionalData.protocolNumber,
          "protocolDate": additionalData.protocolDate
    }],
        "status": status,
        "cciaaRea": additionalData.cciaaRea,
        "address": address,
        "activity": activity,
        "legalForm": legalForm,
        "activityStartDate": additionalData.activityStartDate,
        "legalEmail": additionalData.legalEmail
    }]`,
      inactive: `[creditsafeData.report.directorships.inactive.{
        "companyName": companyName,
        "taxCode": additionalData.taxCode,
        "positions": [position.{
          "positionName": positionName,
          "positiionType": additionalData.positionType,
          "appointmentDate": additionalData.designationDate,
          "chargesProceedingDate": additionalData.chargesProceedingDate,
          "positionType": additionalData.positionType,
          "durationType": apptDurationType,
          "positionDetail": additionalData.positionDetail,
          "registrationDate": additionalData.registrationDate,
          "protocolNumber": additionalData.protocolNumber,
          "protocolDate": additionalData.protocolDate
    }],
        "status": status,
        "cciaaRea": additionalData.cciaaRea,
        "address": address,
        "activity": activity,
        "legalForm": legalForm,
        "activityStartDate": additionalData.activityStartDate,
        "legalEmail": additionalData.legalEmail
    }]`
    },
    tevunaReport: {
      repScore: '(report = "R2" and type = "COMPANY") ? repScore : null',
      repSumm: '(report = "R2" and type = "COMPANY") ?  repSumm : null',
      critIssueSummary: 'critIssueSummary',
      report: 'report',
      critIssueDetails: 'critIssueDetails',
      email: 'userEmail',
      name: 'name',
      createdAt: 'createdAt',
      country: 'country',
      references: 'references'
    }
  };

  const resolvedData = await resolveTransformer(transformer, originalData);

  return {resolvedData, transformer};
}