import { Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PDFDocument } from 'src/app/core/interfaces/pdf-document.interface';
import { MarkdownRendererComponent } from '../../markdown-renderer/markdown-renderer.component';

@Component({
    selector: 'app-media-intelligence',
    imports: [
        MarkdownRendererComponent,
        CommonModule
    ],
    template: `
        <div class="p-4">
            <app-markdown-renderer *ngIf="critIssueDetails" [text]="critIssueDetails"></app-markdown-renderer>
        </div>
    `,
    standalone: true
})
export class MediaIntelligenceComponent {
    @Input() critIssueDetails: string = '';
    @Input() printMode: boolean = false;

    static renderToPdf(doc: PDFDocument, data: any): void {
        // Start on a new page
        doc.addPage();

        // Initialize y position after new page
        let currentY = 50; // Start with top margin
        doc.y = currentY;

        const pageWidth = 612; // US Letter width in points
        const marginLeft = 50;
        const marginRight = 50;
        const contentWidth = pageWidth - marginLeft - marginRight;

        // Draw header background
        doc.rect(marginLeft, currentY, contentWidth, 30)
           .fill('#172554');
        
        // Add header text
        doc.fontSize(14)
           .font('Helvetica-Bold')
           .fill('#ffffff')
           .text('Media Intelligence', marginLeft, currentY + 8, { 
             align: 'center',
             width: contentWidth
           });
        
        // Move below the header rectangle
        currentY += 40;
        doc.y = currentY;
        
        // Reset text color to black for content
        doc.fill('#000000');

        // Render markdown content if it exists
        if (data.tevunaReport?.critIssueDetails) {
            MarkdownRendererComponent.renderToPdf(doc, data.tevunaReport.critIssueDetails, marginLeft, contentWidth);
            currentY = doc.y;
        }

        // Update final y position
        doc.y = currentY;
    }
}