import { Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReportFieldDirective } from 'src/app/core/directives/report-field.directive';
import { PDFDocument } from 'src/app/core/interfaces/pdf-document.interface';
import { PdfTableService } from 'src/app/core/services/pdf-table.service';

@Component({
  selector: 'app-sources',
  standalone: true,
  imports: [ReportFieldDirective, CommonModule],
  template: `
    <div *reportField="'tevunaReport.references'; let references">
    <div class="p-2" *ngFor="let reference of references"><a
                            href="{{reference}}">{{reference}}</a></div>
    </div>
  `
})
export class SourcesComponent {
  @Input() printMode: boolean = false;
  @Input() sources: string = '';

  constructor(private pdfTableService: PdfTableService) {}

  static renderToPdf(doc: PDFDocument, data: any, dueDiligence: any): void {
    if (dueDiligence.references) {

      doc.addPage();
      doc.fontSize(14)
         .font('Helvetica-Bold')
         .text('Sources', { align: 'left' })
         .moveDown(2)
         .fontSize(10)
         .font('Helvetica');
      
      const references = Array.isArray(dueDiligence.references) 
        ? dueDiligence.references 
        : dueDiligence.references.split('\n');

      for (const reference of references) {
        if (!reference.trim()) continue;
        
        doc.fillColor('blue')
           .text(reference, { width: 500 });

        // Measure the text
        const width = doc.widthOfString(reference);
        const height = doc.currentLineHeight();

        // Add link annotation
        doc.link(50, doc.y - height, width, height, reference.trim());

        doc.moveDown(0.5);
      }

      // Reset text color back to black
      doc.fillColor('black');
    }
  }
}