import { Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReportFieldDirective } from 'src/app/core/directives/report-field.directive';
import { PDFDocument } from 'src/app/core/interfaces/pdf-document.interface';
import { PdfTableService } from 'src/app/core/services/pdf-table.service';
import { MarkdownRendererComponent } from '../../markdown-renderer/markdown-renderer.component';

@Component({
  selector: 'app-summary',
  standalone: true,
  imports: [
    CommonModule,
    MarkdownRendererComponent,
    ReportFieldDirective
  ],
  template: `
    <div class="flex w-full flex-wrap my-5">
      <div class="w-full mb-4">
      <div class="border border-grey border-t-cyan-700 w-full">
        <table class="w-full">
          <thead>
            <tr class="bg-blue-950">
              <th colspan="2" class="text-center">
                <h1 class="text-lg p-1 text-white m-0">Key Information</h1>
              </th>
            </tr>
          </thead>
          <tbody>
            <tr *reportField="'generalInfo.companyName'; let companyName">
              <td class="py-2 px-4 font-bold">Company Name:</td>
              <td class="py-2 px-4">{{ companyName }}</td>
            </tr>
            <tr *reportField="'generalInfo.monitoredEntities'; let monitoredEntities">
              <td class="py-2 px-4 font-bold">Monitored Entities:</td>
              <td class="py-2 px-4">{{ monitoredEntities.join(', ') }}</td>
            </tr>
            <tr *reportField="'generalInfo.vatNumber', let vatNumber">
              <td class="py-2 px-4 font-bold">VAT Number:</td>
              <td class="py-2 px-4">{{ vatNumber }}</td>
            </tr>
            <tr *reportField="'generalInfo.registrationNumber'; let registrationNumber">
                    <td class="py-2 px-4 font-bold">Registration Number:</td>
                    <td class="py-2 px-4">{{ registrationNumber }}
                    </td>
              </tr>
              <tr *reportField="'generalInfo.incorporationDate'; let incorporationDate">
                  <td class="py-2 px-4 font-bold">Incorporation Date:</td>
                  <td class="py-2 px-4">{{ incorporationDate |
                      date }}</td>
              </tr>
              <tr *reportField="'generalInfo.legalForm'; let legalForm">
                  <td class="py-2 px-4 font-bold">Legal Form:</td>
                  <td class="py-2 px-4">{{ legalForm }}</td>
              </tr>
              <tr *reportField="'generalInfo.taxCode'; let taxCode">
                  <td class="py-2 px-4 font-bold">Tax Code:</td>
                  <td class="py-2 px-4">{{ taxCode }}</td>
              </tr>
              <tr *reportField="'generalInfo.otherLegalName'; let value">
                  <td class="py-2 px-4 font-bold">Other Legal Name:</td>
                  <td class="py-2 px-4">{{
                    value
                      }}</td>
              </tr>
              <tr *reportField="'generalInfo.charterNumber'; let value">
                  <td class="py-2 px-4 font-bold">Charter Number:</td>
                  <td class="py-2 px-4">{{
                    value
                      }}</td>
              </tr>
              <tr *reportField="'generalInfo.establishmentDate'; let value">
                  <td class="py-2 px-4 font-bold">Establishment Date:</td>
                  <td class="py-2 px-4">{{
                    value
                      | date }}</td>
              </tr>
              <tr
                  *reportField="'generalInfo.companyType'; let value">
                  <td class="py-2 px-4 font-bold">Company Type:</td>
                  <td class="py-2 px-4">{{
                    value }}
                  </td>
              </tr>
              <tr *reportField="'generalInfo.sicDescription'; let value">
                  <td class="py-2 px-4 font-bold">SIC Description:</td>
                  <td class="py-2 px-4">{{
                    value
                      }}</td>
              </tr>
          </tbody>
        </table>
      </div>
      </div>
      <div class="w-full border-1 border-grey px-2" *reportField="'tevunaReport.critIssueSummary'; let criticalIssueSummary">
        <h1 class="text-3xl pb-5 font-extrabold">Critical Issues Summary</h1>
        <app-markdown-renderer *ngIf="criticalIssueSummary" [text]="criticalIssueSummary"></app-markdown-renderer>
      </div>
    </div>
  `
})
export class SummaryComponent {
  @Input() printMode: boolean = false;

  constructor(private pdfTableService: PdfTableService) {}

  static renderToPdf(doc: PDFDocument, data: any): void {
    const pageWidth = 612; // US Letter width in points
    const marginLeft = 50;
    const marginRight = 50;
    const contentWidth = pageWidth - marginLeft - marginRight;

    let currentY = doc.y;
    
    // Key Information Section
    doc.rect(marginLeft, currentY, contentWidth, 30)
       .fill('#172554'); // bg-blue-950
    
    doc.fontSize(14)
       .font('Helvetica-Bold')
       .fill('#ffffff')
       .text('Key Information', marginLeft, currentY + 8, { 
         align: 'center',
         width: contentWidth
       });

    currentY += 40; // Move below header
    doc.fill('#000000');

    const headers = ['', ''];
    const rows = [
      ['Company Name', data.generalInfo?.companyName || ''],
      ['Monitored Entities', data.generalInfo?.monitoredEntities?.join(', ') || ''],
      ['VAT Number', data.generalInfo?.vatNumber || ''],
      ['Registration Number', data.generalInfo?.registrationNumber || ''],
      ['Incorporation Date', data.generalInfo?.incorporationDate || ''],
      ['Legal Form', data.generalInfo?.legalForm || ''],
      ['Tax Code', data.generalInfo?.taxCode || ''],
      ['Other Legal Name', data.generalInfo?.otherLegalName || ''],
      ['Charter Number', data.generalInfo?.charterNumber || ''],
      ['Establishment Date', data.generalInfo?.establishmentDate || ''],
      ['Company Type', data.generalInfo?.companyType || ''],
      ['SIC Description', data.generalInfo?.sicDescription || '']
    ].filter(row => row[1]);

    if (rows.length > 0) {
      currentY = PdfTableService.drawTable(doc, headers, rows, currentY);
    }

    if (data.tevunaReport?.critIssueSummary) {
      doc.moveDown(2);
      currentY = doc.y;
      
      // Critical Issues Summary Header
      doc.rect(marginLeft, currentY, contentWidth, 30)
         .fill('#172554');
      
      doc.fontSize(14)
         .font('Helvetica-Bold')
         .fill('#ffffff')
         .text('Critical Issues Summary', marginLeft, currentY + 8, { 
           align: 'center',
           width: contentWidth
         });
      
      currentY += 40; // Move below header
      doc.y = currentY;
      
      // Reset text color to black for content
      doc.fill('#000000');
      
      // Critical Issues Summary content
      MarkdownRendererComponent.renderToPdf(doc, data.tevunaReport.critIssueSummary, marginLeft, contentWidth);
      currentY = doc.y;
    }

    // Update the final y position
    doc.y = currentY;
  }
}