import { CommonModule } from '@angular/common';
import { Component, Inject, OnInit, inject } from '@angular/core';
import { FormControl, FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { PostDiligenceParams } from 'src/app/core/models/params';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { MatButtonModule } from '@angular/material/button';
import { Router } from '@angular/router';
import { MAT_DIALOG_DATA, MatDialog, MatDialogModule, MatDialogRef } from '@angular/material/dialog';
import { ExpiryDateFormatterDirective } from 'src/app/core/directives/format-expiration.directive';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatSelectModule } from '@angular/material/select';
import { MatSnackBar } from '@angular/material/snack-bar';
import { AuthService } from '@auth0/auth0-angular';
import { EMPTY, Observable, catchError, combineLatestWith, of, switchMap, take, tap } from 'rxjs';
import { ApiService } from 'src/app/core/services/api.service';
import { MyAuthService } from 'src/app/core/services/auth.service';
import { Package } from '../.../../../../core/models/package';
import { environment } from 'src/environments/environment';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { TosComponent } from 'src/app/features/tos/tos.component';
import {packages} from 'src/app/core/models/packages';
import { Credit } from 'src/app/core/models/credit';

interface SelectedPackage {
  company: any;
  packages: Package[];
  uboSelected: boolean;
}

interface OrderSummary {
  totalReports: number;
  availableCredits: number;
  creditsToUse: number;
  reportsNeedingPayment: number;
  totalPaymentNeeded: number;
  uboTotal: number;
  baseTotal: number;
  priceBeforeDeduction: number;
}

@Component({
  selector: 'app-post-diligence-dialog',
  templateUrl: './post-diligence-dialog.component.html',
  styleUrls: ['./post-diligence-dialog.component.scss'],
  standalone: true,
  imports: [
    MatButtonModule, CommonModule, MatDialogModule, MatFormFieldModule, MatSelectModule, MatInputModule, FormsModule, ReactiveFormsModule,
    MatFormFieldModule, MatProgressSpinnerModule, MatIconModule, ExpiryDateFormatterDirective, MatCheckboxModule
  ],
  providers: [MatSnackBar]
})
export class PostDiligenceDialogComponent implements OnInit {
  apiService = inject(ApiService);
  auth = inject(AuthService);
  myAuthService = inject(MyAuthService);
  snackBar = inject(MatSnackBar);
  dialog = inject(MatDialog);
  packages = packages;

  isAdmin$: Observable<boolean> = this.myAuthService.isAdmin();

  formGroup!: FormGroup;

  selectedPackages: Package[] = [];
  uboSelected = false;
  userHasPayed = false;
  loading = false;
  error = false;
  termsAccepted = false;
  hasCredits = false;
  checkingCredits = true;
  currentPhase: 'selection' | 'confirmation' = 'selection';

  credits: Credit[] = [];
  orderSummary: OrderSummary = {
    totalReports: 0,
    availableCredits: 0,
    creditsToUse: 0,
    reportsNeedingPayment: 0,
    totalPaymentNeeded: 0,
    uboTotal: 0,
    baseTotal: 0,
    priceBeforeDeduction: 0
  };

  constructor(
    public dialogRef: MatDialogRef<PostDiligenceDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any[],
    private router: Router
  ) { }

  onSubmit() {
    if (this.formGroup.getRawValue().xAmount > 0) {
      this.onPaySubmit();
    } else {
      this.submitDiligence();
    }
  }

  onPaySubmit() {
    if (this.formGroup.valid) {
      this.loading = true;
      this.error = false;
      
      const orderData = {
        packages: this.selectedPackages.map(pkg => pkg.code),
        companies: this.data.map(company => company.target),
        uboSelected: this.uboSelected,
        paymentDetails: {
          xCVV: this.formGroup.value.xCVV,
          xExp: this.formGroup.value.xExp.replace(/\D/g, ''),
          xCardNum: this.formGroup.value.xCardNum,
          xBillFirstName: this.formGroup.value.xBillFirstName,
          xBillLastName: this.formGroup.value.xBillLastName,
          xBillStreet: this.formGroup.value.xBillStreet,
          xBillCity: this.formGroup.value.xBillCity,
          xBillState: this.formGroup.value.xBillState,
          xBillZip: this.formGroup.value.xBillZip,
          xBillMobile: this.formGroup.value.xBillMobile,
        }
      };

      this.auth.user$.pipe(
        switchMap(user => this.apiService.postSale(orderData)),
        catchError(err => {
          this.loading = false;
          this.error = true;
          return EMPTY;
        }),
        combineLatestWith(this.isAdmin$),
      ).subscribe(([response, isAdmin]) => {
        this.loading = false;
        if (response?.success || isAdmin) {
          this.userHasPayed = true;
          this.submitDiligence();
        } else {
          this.error = true;
        }
      });
    }
    else {	
      Object.keys(this.formGroup.controls).forEach(key => {	
        const control = this.formGroup.get(key);	
        control?.markAsTouched();	
      });	
    }
  }

  submitDiligence() {
    this.loading = true;
    this.error = false;

    const diligenceData = {
      companies: this.data.map(company => ({
        country: company.country,
        type: company.type,
        csid: company.id || company.peopleId,
        name: company.name,
        target: company.target
      })),
      packages: this.selectedPackages.map(pkg => pkg.code),
      ubo: this.uboSelected
    };

    this.apiService.postDiligence(diligenceData).subscribe({
      next: () => {
        this.loading = false;
        this.snackBar.open(
          'Due Diligence is ongoing, you will receive a notification once the report is ready. This may take up to 24h. You can check the report status in the "My Reports" section in your account.',
          'Close',
          { duration: 5000 }
        );
        this.dialogRef.close();
        this.router.navigate(['/myaccount']);
      },
      error: () => {
        this.loading = false;
        this.error = true;
        this.snackBar.open(
          'An error occurred during Due Diligence. Please try again later.',
          'Close',
          { duration: 5000 }
        );
      }
    });
  }

  openTermsAndConditions() {
    this.dialog.open(TosComponent, {
      width: '80%',
      height: '80%'
    });
  }

  togglePackage(pack: Package) {
    const packageIndex = this.selectedPackages.findIndex(p => p.code === pack.code);
    if (packageIndex !== -1) {
      this.selectedPackages.splice(packageIndex, 1);
    } else {
      this.selectedPackages.push(pack);
    }
    this.updateTotalAmount();
  }

  isPackageSelected(pack: Package): boolean {
    return this.selectedPackages.some(p => p.code === pack.code);
  }

  hasUboAvailable(): boolean {
    return this.selectedPackages.some(pkg => pkg.ubo_add_on !== false);
  }

  getUboSupportedPackages(): Package[] {
    return this.selectedPackages.filter(pkg => pkg.ubo_add_on !== false);
  }

  get uboSupportedPackageNames(): string {
    return this.getUboSupportedPackages().map(p => p.name).join(', ');
  }

  updateTotalAmount() {
    this.calculateOrderSummary();
  }

  calculateOrderSummary() {
    const totalReports = this.data.length * this.selectedPackages.length;
    const availableCredits = this.credits.reduce((sum, credit) => sum + credit.remaining_amount, 0);
    
    // Create array of all reports with their prices
    const reportPrices = this.selectedPackages.flatMap(pkg => 
      Array(this.data.length).fill({
        basePrice: pkg.price,
        uboPrice: this.uboSelected && pkg.ubo_add_on !== false ? pkg.ubo_add_on : 0
      })
    );

    // Sort by base price descending (most expensive first)
    reportPrices.sort((a, b) => b.basePrice - a.basePrice);

    // Apply credits to most expensive reports first
    const creditsToUse = Math.min(totalReports, availableCredits);
    const reportsNeedingPayment = reportPrices.slice(creditsToUse);

    // Calculate totals
    const baseTotal = reportsNeedingPayment.reduce((sum, report) => sum + report.basePrice, 0);
    
    // UBO is charged only for packages that support it
    const uboTotal = this.uboSelected ? 
      reportPrices.reduce((sum, report) => sum + report.uboPrice, 0) : 0;

    const totalPaymentNeeded = baseTotal + uboTotal;

    this.orderSummary = {
      totalReports,
      availableCredits,
      creditsToUse,
      reportsNeedingPayment: reportsNeedingPayment.length,
      totalPaymentNeeded,
      priceBeforeDeduction: reportPrices.reduce((sum, report) => sum + report.basePrice, 0),
      uboTotal,
      baseTotal
    };

    this.formGroup.patchValue({
      xAmount: totalPaymentNeeded
    });
  }

  goToConfirmation() {
    if (this.selectedPackages.length > 0) {
      this.currentPhase = 'confirmation';
    }
  }

  goBackToSelection() {
    this.currentPhase = 'selection';
  }

  getCreditDeduction(): number {
    const basePrice = this.orderSummary.priceBeforeDeduction
    return basePrice - this.orderSummary.totalPaymentNeeded
  }

  ngOnInit(): void {
    this.formGroup = new FormGroup({
      'xCardNum': new FormControl(null, [Validators.required, Validators.pattern('^[0-9]{16}$')]),
      "xCVV": new FormControl(null, [Validators.required, Validators.minLength(3), Validators.maxLength(4)]),
      "xExp": new FormControl(null, [Validators.required, Validators.minLength(4), Validators.maxLength(5)]),
      "xAmount": new FormControl({ value: null, disabled: true }, Validators.required),
      "xBillFirstName": new FormControl(null, Validators.required),
      "xBillLastName": new FormControl(null, Validators.required),
      "xBillStreet": new FormControl(null, Validators.required),
      "xBillCity": new FormControl(null, Validators.required),
      "xBillState": new FormControl(null, Validators.required),
      "xBillZip": new FormControl(null, [Validators.required, Validators.minLength(5), Validators.maxLength(5)]),
      "xBillMobile": new FormControl(null, [Validators.required, Validators.minLength(10), Validators.maxLength(11)]),
      "termsAccepted": new FormControl(false, Validators.requiredTrue)
    });

    this.apiService.listCredits().pipe(
      take(1),
      tap(credits => {
        this.credits = Array.isArray(credits) ? credits : [];
        this.checkingCredits = false;
        this.calculateOrderSummary();
      })
    ).subscribe();
  }
}
